
import { defineComponent, computed } from 'vue'
import dayjs from 'dayjs'
import { ChainType } from '@/libs/everpay-js'
import { getExplorerUrl } from '@/libs/utils'
import { useI18n } from 'vue-i18n'
import MoreInfo from '@/components/common/MoreInfo.vue'
export default defineComponent({
  components: {
    MoreInfo
  },
  props: {
    arId: {
      type: String,
      default: ''
    },
    datetime: {
      type: Number,
      default: 0
    },
    txNum: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const time = dayjs(+props.datetime * 1000).format('YYYY-MM-DD HH:mm:ss')
    const { t } = useI18n()
    const formatInfo = (info: string) => {
      return info.slice(0, 8) + '...'
    }
    const targetChainTxHashUrl = computed(() => {
      return props.arId
        ? getExplorerUrl({
          type: 'tx',
          value: props.arId
        }, ChainType.arweave)
        : ''
    })
    return {
      t,
      time,
      targetChainTxHashUrl,
      formatInfo
    }
  }
})

<template>
  <div
    class="h-8 min-w-32px cursor-pointer flex  border justify-center border-solid transition-colors items-center rounded bg-white">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    current: {
      type: Number,
      default: 1
    },
    item: {
      type: Number,
      default: 1
    }
  }
})
</script>

<style>

</style>

<template>
  <Search class="flex justify-center pb-4 lg:hidden w-full px-6" />
  <div class="max-w-1200px p-4 mx-5 xl:mx-auto xl:w-1200px bg-white rounded-2xl" style="box-shadow: 4px 4px 50px rgba(244, 239, 244, 0.5);">
    <slot />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Search from './Search.vue'
export default defineComponent({
  components: {
    Search
  }
})
</script>

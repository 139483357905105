<template>
  <div class="bg-scanMain pt-20 lg:pt-28 pb-12">
    <Container>
      <div class="px-4">
        <MoreInfo info="Rollup txs">
          {{ t('covering_rollup_tx_everpay') }}
        </MoreInfo>
      </div>
      <Loading v-if="loading" />
      <div v-else class="overflow-x-scroll lg:overflow-auto overflow-hidden">
        <div class="min-w-768px">
          <div class="px-4 mt-4 flex justify-between items-center text-sm rounded-lg bg-everWhite1 h-14">
            <div class="flex justify-between  items-center w-full ">
              <div class="w-28">
                Rollup tx
              </div>
              <div class="w-36">
                {{ t('date_time') }}
              </div>
              <div class="w-24">
                {{ t('contain_tx_nums') }}
              </div>
              <div class="w-28">
                {{ t('more') }}
              </div>
            </div>
          </div>
          <AridList
            v-for="(item,index) in aridsList"
            :key="index"
            :ar-id="item.arId"
            :tx-num="item.rollupTxNum"
            :datetime="item.timestamp"
            :class="index < aridsList.length - 1 ? 'border-b border-black-500 border-solid' : ''" />
        </div>
      </div>
      <Pagination
        :loading="loading"
        :current-page="currentPage"
        :has-next-page="isNextPage"
        @jump="handlePageChange" />
    </Container>
  </div>
</template>

<script lang="ts">
import { defineComponent, onActivated, onDeactivated, ref, Ref } from 'vue'
import Container from '@/components/Container.vue'
import Pagination from '@/components/common/Pagination.vue'
import { getArIdTxs } from '@/libs/api'
import AridList from './List.vue'
import Loading from '@/components/common/Loading.vue'
import { useI18n } from 'vue-i18n'
import { ArIdTxs } from '@/pages/interface'
import { handleErrorMsg } from '@/libs/utils'
import MoreInfo from '@/components/common/MoreInfo.vue'
export default defineComponent({
  components: {
    Container,
    Pagination,
    AridList,
    Loading,
    MoreInfo
  },
  setup () {
    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const loading = ref(false)
    const aridsList: Ref<ArIdTxs[]> = ref([])
    const { t } = useI18n()
    const getTxsList = async () => {
      loading.value = true
      try {
        if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
          pageId.value = pageCachelist.value[pageCacheIndex.value]
        }
        const arTxResult = await getArIdTxs(pageId.value)
        isNextPage.value = arTxResult.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (arTxResult.hasNextPage) {
          pageId.value = arTxResult.arTxs[arTxResult.arTxs.length - 1].id
        }

        aridsList.value = arTxResult.arTxs
        // aridsList.value = arTxResult.arTxs
        // pageNum.value = Math.ceil(arTxResult.total / 10)
      } catch (e:any) {
        handleErrorMsg(e, t)
        aridsList.value = []
      } finally {
        loading.value = false
      }
    }
    const handlePageChange = (page: number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getTxsList()
    }
    onActivated(() => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
      getTxsList()
    })
    onDeactivated(() => {
      aridsList.value = []
    })
    return {
      t,
      aridsList,
      loading,
      handlePageChange,
      currentPage,
      isNextPage
    }
  }
})
</script>

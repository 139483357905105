
import { defineComponent } from 'vue'
import PaginationItem from './PaginationItem.vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: {
    PaginationItem
  },
  props: {
    pageNum: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    hasNextPage: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['jump'],
  setup (props, context) {
    const { t } = useI18n()
    const prevPage = () => {
      if (props.currentPage <= 1 || props.loading) return
      context.emit('jump', props.currentPage - 1)
    }
    const nextPage = () => {
      if (!props.hasNextPage || props.loading) return
      context.emit('jump', props.currentPage + 1)
    }
    const goFirstPage = () => {
      if (props.currentPage <= 1 || props.loading) return
      context.emit('jump', 1)
    }
    return {
      t,
      prevPage,
      nextPage,
      goFirstPage
    }
  }

})

<template>
  <div class="flex sm:justify-end justify-start mt-10 mb-3 mx-auto w-full">
    <div class="text-sm flex justify-between items-center">
      <PaginationItem class="px-2.5 border-black border-opacity-12 whitespace-nowrap" :class="currentPage === 1 ? 'text-black text-opacity-25 cursor-not-allowed' : 'text-scanMainColor hover:border-scanMainColor hover:border-opacity-100 active:border-scanMainColor active:border-opacity-100'" @click="goFirstPage">
        {{ t('first') }}
      </PaginationItem>
      <div class="Pagination-line h-5 w-px bg-black bg-opacity-10 mx-2" />
      <PaginationItem class="border-black border-opacity-12" :class="currentPage === 1 ? 'cursor-not-allowed' : 'text-scanMainColor hover:border-scanMainColor hover:border-opacity-100 active:border-scanMainColor active:border-opacity-100'" @click="prevPage">
        <el-icon style="font-size: 10px;" :class="currentPage === 1 ? 'opacity-25' : ''">
          <ArrowLeftBold />
        </el-icon>
      </PaginationItem>
      <div class="mx-2">
        <PaginationItem class="px-2.5 whitespace-nowrap text-black text-opacity-55">
          {{ t('pagination',{current:currentPage}) }}
        </PaginationItem>
      </div>
      <PaginationItem class="border-black border-opacity-12" :class="!hasNextPage ? 'cursor-not-allowed' : 'text-scanMainColor hover:border-scanMainColor hover:border-opacity-100 active:border-scanMainColor active:border-opacity-100'" @click="nextPage">
        <el-icon style="font-size: 10px;" :class="!hasNextPage ? 'opacity-25' : ''">
          <ArrowRightBold />
        </el-icon>
      </PaginationItem>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PaginationItem from './PaginationItem.vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: {
    PaginationItem
  },
  props: {
    pageNum: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    hasNextPage: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['jump'],
  setup (props, context) {
    const { t } = useI18n()
    const prevPage = () => {
      if (props.currentPage <= 1 || props.loading) return
      context.emit('jump', props.currentPage - 1)
    }
    const nextPage = () => {
      if (!props.hasNextPage || props.loading) return
      context.emit('jump', props.currentPage + 1)
    }
    const goFirstPage = () => {
      if (props.currentPage <= 1 || props.loading) return
      context.emit('jump', 1)
    }
    return {
      t,
      prevPage,
      nextPage,
      goFirstPage
    }
  }

})
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
